import React, { FC } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import BillIcon from '../../../../../icons/BillIcon';
import { getQueryParam } from '../../../../../utils/queryParams';

const OrderInfoWrapper = styled(Link)`
    align-items: center;
    background-color: #6b257b;
    bottom: 0;
    color: #ffffff;
    cursor: pointer;
    display: flex;
    font-size: 18px;
    font-weight: 300;
    height: 55px;
    justify-content: space-between;
    line-height: 18px;
    padding: 0 30px;
    position: fixed;
    text-decoration: none;
    width: 100%;
`;
const OrderInfoBillIconAndTextWrapper = styled.div`
    align-items: center;
    display: flex;
`;
const OrderInfoBillIconWrapper = styled.div`
    margin-right: 12px;
`;
interface OrderInfoInterface {
    cartTotalCost: number;
}

const OrderInfo: FC<OrderInfoInterface> = ({ cartTotalCost }) => {
	const { t } = useTranslation();
	const tableId = getQueryParam('tableId');
	const restaurantId = getQueryParam('restaurantId');
	return (
		<OrderInfoWrapper to={`/table/menu/details/cart?tableId=${tableId}&restaurantId=${restaurantId}`}>
			<OrderInfoBillIconAndTextWrapper>
				<OrderInfoBillIconWrapper><BillIcon /></OrderInfoBillIconWrapper>
				{t('common.your')}
				<b>
                    &nbsp;
					{t('common.orderNoun')}
				</b>
			</OrderInfoBillIconAndTextWrapper>
			{`${(cartTotalCost / 100).toFixed(2)} zł`}
		</OrderInfoWrapper>
	);
};
export default OrderInfo;
