import React, { FC } from 'react';
import styled from 'styled-components';
import { QuantityInfoInterface } from 'restpay-api-lib/lib/@types/_interfaces/menus/quantityInfo.interface';
import { ItemInterface } from 'restpay-api-lib/lib/@types/_interfaces/menus/item.interface';
import { ModifierDisplayTypeEnum } from '../../../@types/_enums/menu/modifierDisplayType.enum';
import MultipleChoiceWithCounter from './MultipleChoiceWithCounter';
import SingleChoice from './SingleChoice';
import MultipleChoice from './MultipleChoice';

const ItemModifierInputWrapper = styled.div`
    align-items: center;
    display: flex;
`;

export interface ItemModifierInputInterface {
    modifier: ItemInterface;
    quantityInfo: QuantityInfoInterface;
    groupOfModifiersId: string;
    modifierDisplayType: ModifierDisplayTypeEnum;
    refreshQuantitySummaryInMultipleChoiceWithCounter: () => void;
}

const ItemModifierInput: FC<ItemModifierInputInterface> = ({
	modifier,
	quantityInfo,
	groupOfModifiersId,
	modifierDisplayType,
	refreshQuantitySummaryInMultipleChoiceWithCounter,
}) => (
	<ItemModifierInputWrapper>
		{modifierDisplayType === ModifierDisplayTypeEnum.SINGLE_CHOICE && (
			<SingleChoice
				groupOfModifiersId={groupOfModifiersId}
				modifier={modifier}
			/>
		)}
		{modifierDisplayType === ModifierDisplayTypeEnum.MULTIPLE_CHOICE && (
			<MultipleChoice
				groupOfModifiersId={groupOfModifiersId}
				modifier={modifier}
			/>
		)}
		{modifierDisplayType === ModifierDisplayTypeEnum.MULTIPLE_CHOICE_WITH_COUNTER && (
			<MultipleChoiceWithCounter
				groupOfModifiersId={groupOfModifiersId}
				modifier={modifier}
				quantityInfo={quantityInfo}
				refreshQuantitySummaryInMultipleChoiceWithCounter={refreshQuantitySummaryInMultipleChoiceWithCounter}
			/>
		)}
	</ItemModifierInputWrapper>
);
export default ItemModifierInput;
