import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ImageTypeEnum } from 'restpay-api-lib/lib/@types/_enums/image.enum';
import { CategoryInterface } from 'restpay-api-lib/src/@types/_interfaces/menus/category.interface';
import { LoadingCircle } from '../../../components/common/loading';
import { GlobalState } from '../../../state/@types/redux.interface';
import MenuDetailsHeaderImage from '../../../components/table/menu/details/MenuDetailsHeaderImage';
import RestaurantDetails from '../../../components/table/menu/details/restaurantDetails/RestaurantDetails';
import CategoriesRow from '../../../components/table/menu/details/categoriesRow/CategoriesRow';
import useGetIdsFromUrlAndFetch from '../../../utils/useGetIdsFromUrlAndFetch';
import ItemsSection from '../../../components/table/menu/details/itemsSection/ItemsSection';
import OrderInfo from '../../../components/table/menu/details/orderInfo/OrderInfo';
import { ItemInOrderInterface } from '../../../state/@types/cart/itemInOrder.interface';
import { setCurrentRestaurantItems, setCurrentRestaurantOrdersAction } from '../../../state/cart.reducer';
import { ItemInCartInterface } from '../../../state/@types/cart/itemInCart.interface';
import { calculateCostInRestaurantUtil } from '../../../utils/calculateCostInRestaurantUtil';

const DetailsWrapper = styled.div`
    height: 100%;
    width: 100%;
`;

const DetailsLoadingWrapper = styled.div`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
`;

const DetailsContentWrapper = styled.div`
    background-color: #FBFBFB;
    width: 100%;
`;
const Details: FC = () => {
	useGetIdsFromUrlAndFetch();
	const dispatch = useDispatch();

	const menuSelector = useSelector((state: GlobalState) => state.menu);
	const tableSelector = useSelector((state: GlobalState) => state.table);
	const cartSelector = useSelector((state: GlobalState) => state.cart);
	const [cartTotalCost, setCartTotalCost] = useState<number>(0);
	const [menuCategoriesThatHaveItems, setMenuCategoriesThatHaveItems] = useState<CategoryInterface[]>([]);

	const {
		loading: loadingMenu, error: errorMenu, fetched: fetchedMenu, menu,
	} = menuSelector;

	const {
		loading: loadingTable, error: errorTable, fetched: fetchedTable, table,
	} = tableSelector;

	const { orders, ordersInCurrentRestaurant } = cartSelector;

	const getRestaurantLogo = (): string => menu?.restaurant?.images.find((image) => image.type === ImageTypeEnum.LOGO)?.url || '/table/logo.svg';
	const getRestaurantMiniature = (): string => menu?.restaurant?.images.find((image) => image.type === ImageTypeEnum.MINIATURE)?.url || '/table/tło.svg';

	const getOrdersInCurrentRestaurant = (): ItemInCartInterface[] =>
		orders.filter((order) => order?.restaurantId === menu?.restaurant?.id);

	useEffect(() => {
		if (fetchedMenu) {
			const currentRestaurantOrders = getOrdersInCurrentRestaurant();
			dispatch(setCurrentRestaurantOrdersAction(currentRestaurantOrders));
			setCartTotalCost(calculateCostInRestaurantUtil(currentRestaurantOrders));
		}
	}, [orders, fetchedMenu]);

	useEffect(() => {
		setMenuCategoriesThatHaveItems(
			menu?.categories
				.sort(((a, b) => a?.orderInfo?.order - b?.orderInfo?.order))
				.filter((category) => category?.items?.length > 0),
		);
	}, [menu]);

	return (
		<>
			<DetailsWrapper>
				{(loadingMenu || loadingTable) && <DetailsLoadingWrapper><LoadingCircle /></DetailsLoadingWrapper>}
				{fetchedMenu && fetchedTable && (
					<>
						<MenuDetailsHeaderImage
							bgImg={getRestaurantMiniature()}
							tableId={table.id}
						/>
						<DetailsContentWrapper>
							<RestaurantDetails
								estimatedTime={`${menu?.restaurant?.averageOrderPreparationTime ?? 20} min`}
								numberOfOpinions={menu?.restaurant?.ratingsCount}
								ratingScore={(menu?.restaurant?.ratingsSum || 0) / (menu?.restaurant?.ratingsCount || 1)}
								restaurantDescription={menu?.restaurant?.description}
								restaurantLogoUrl={getRestaurantLogo()}
								restaurantName={menu?.restaurant?.name}
							/>
							{menuCategoriesThatHaveItems?.length > 0 && (
								<>
									<CategoriesRow categories={menuCategoriesThatHaveItems} />
									<ItemsSection
										categories={menuCategoriesThatHaveItems}
										items={menu.items}
									/>
								</>
							)}
						</DetailsContentWrapper>
					</>
				)}
			</DetailsWrapper>
			{ordersInCurrentRestaurant?.length > 0 && <OrderInfo cartTotalCost={cartTotalCost} />}
		</>
	);
};
export default Details;
