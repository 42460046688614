import React, { FC } from 'react';
import styled from 'styled-components';

const RestaurantLogoWrapper = styled.div`
    background-image: url('${({ restaurantLogoUrl }) => restaurantLogoUrl}');
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 1px 10px 0 rgba(0, 0, 0, 0.35);
    height: 72px;
    width: 72px;
`;

interface RestaurantLogoInterface {
    restaurantLogoUrl: string;
}

const RestaurantLogo: FC<RestaurantLogoInterface> = ({ restaurantLogoUrl }) => (
	<RestaurantLogoWrapper
		alt="Restaurant logo"
		restaurantLogoUrl={restaurantLogoUrl}
	/>
);
export default RestaurantLogo;
