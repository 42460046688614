import React, { FC } from 'react';
import styled from 'styled-components';
import HeartIcon from '../../../../../icons/heartIcon';
import RestaurantLogo from './RestaurantLogo';
import RestaurantRating from './RestaurantRating';
import RestaurantAverageTime from './RestaurantAverageTime';
import RestaurantDescription from './RestaurantDescription';

const RestaurantDetailsWrapper = styled.div`
    display: flex;
    height: 100%;
    margin-bottom: 15px;
    padding: 30px 30px 0;
    width: 100%;
`;

const RestaurantDetailsLogoWrapper = styled.div`
    margin-right: 30px;
`;

const RestaurantDetailsInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
`;
const RestaurantDetailsName = styled.div`
    align-items: center;
    display: flex;
    font-size: 14px;
    font-weight: bold;
    justify-content: space-between;
    line-height: 14px;
    margin-bottom: 10px;
    width: 100%;
`;
const RestaurantDetailsRating = styled.div`
    margin-bottom: 10px;
`;
const RestaurantDetailsAverageTime = styled.div`
    margin-bottom: 10px;
`;

interface RestaurantDetailsInterface {
    restaurantLogoUrl: string;
    restaurantName: string;
    numberOfOpinions: number;
    ratingScore: number;
    estimatedTime: string;
    restaurantDescription: string;
}

const RestaurantDetails: FC<RestaurantDetailsInterface> = ({
	restaurantLogoUrl, restaurantName, numberOfOpinions, ratingScore, estimatedTime, restaurantDescription,
}) => (
	<RestaurantDetailsWrapper>
		<RestaurantDetailsLogoWrapper>
			<RestaurantLogo restaurantLogoUrl={restaurantLogoUrl || ''} />
		</RestaurantDetailsLogoWrapper>
		<RestaurantDetailsInfoWrapper>
			<RestaurantDetailsName>
				{restaurantName}
				<div>
					<HeartIcon />
				</div>
			</RestaurantDetailsName>
			<RestaurantDetailsRating>
				<RestaurantRating
					numberOfOpinions={numberOfOpinions}
					ratingScore={ratingScore}
				/>
			</RestaurantDetailsRating>
			<RestaurantDetailsAverageTime>
				<RestaurantAverageTime estimatedTime={estimatedTime} />
			</RestaurantDetailsAverageTime>
			{restaurantDescription && <RestaurantDescription restaurantDescription={restaurantDescription} />}
		</RestaurantDetailsInfoWrapper>
	</RestaurantDetailsWrapper>
);
export default RestaurantDetails;
