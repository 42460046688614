/* eslint-disable */
import React, { FC, SVGProps } from "react";

const HeartIcon: FC = (props: SVGProps<SVGSVGElement>) => (
    <svg height={12.978} viewBox="0 0 12.978 14.444" width={14.444} xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M10.472 0a4.363 4.363 0 0 0-3.25 1.478A4.363 4.363 0 0 0 3.972 0 3.893 3.893 0 0 0 0 3.89c0 2.673 2.456 4.852 6.175 8.162l1.047.926 1.047-.934c3.719-3.3 6.175-5.481 6.175-8.154A3.893 3.893 0 0 0 10.472 0ZM7.294 11l-.072.071L7.15 11C3.712 7.949 1.444 5.934 1.444 3.89a2.443 2.443 0 0 1 2.528-2.476 2.825 2.825 0 0 1 2.579 1.67H7.9a2.807 2.807 0 0 1 2.571-1.669A2.443 2.443 0 0 1 13 3.89c0 2.044-2.268 4.059-5.706 7.11Z"
            data-name="Path 3853"
        />
    </svg>
);

export default HeartIcon;
