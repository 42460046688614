import React, { FC, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ItemInterface } from 'restpay-api-lib/lib/@types/_interfaces/menus/item.interface';
import defineAddonInputName from '../../../utils/defineAddonInputName';
import { ModifierDisplayTypeEnum } from '../../../@types/_enums/menu/modifierDisplayType.enum';
import CheckedCheckboxIcon from '../../../icons/CheckedCheckboxIcon';
import UncheckedInput from '../../../icons/UncheckedInput';
import { calculateModifierPrice } from '../../../utils/calculateModifierPrice';
import { showPriceUtil } from '../../../utils/showPriceUtil';
import { translateTitle } from '../../../utils/translateTitle';
import { ItemModifierInputLabel, ItemModifierInputInput } from './shared';

interface MultipleChoiceInterface {
    groupOfModifiersId: string;
    modifier: ItemInterface;
}

const MultipleChoice: FC<MultipleChoiceInterface> = ({ groupOfModifiersId, modifier }) => {
	const { control } = useFormContext();
	const [modifierPrice, setModifierPrice] = useState<number>(0);

	useEffect(() => {
		setModifierPrice(calculateModifierPrice(modifier, groupOfModifiersId));
	}, []);

	return (
		<Controller
			control={control}
			defaultValue={[]}
			name={defineAddonInputName(ModifierDisplayTypeEnum.MULTIPLE_CHOICE, groupOfModifiersId)?.inputName}
			render={({
				field: {
					onChange, value,
				},
			}) => {
				const handleSelect = () =>
					onChange(value?.includes(modifier.id) ? value?.filter((name) => name !== modifier.id) : [...(value ?? []), modifier.id]);
				const isChecked = value?.includes(modifier.id);
				return (
					<ItemModifierInputLabel checked={isChecked}>
						{isChecked ? <CheckedCheckboxIcon /> : <UncheckedInput />}
						<ItemModifierInputInput
							onChange={handleSelect}
							type="checkbox"
							value={modifier.id}
						/>
						{translateTitle(modifier.title)}
                        &nbsp;
						{modifierPrice > 0 && (
							<b>{`(+${showPriceUtil(modifierPrice)})`}</b>
						)}
					</ItemModifierInputLabel>
				);
			}}
		/>
	);
};
export default MultipleChoice;
