import * as React from 'react';
import { SVGProps } from 'react';

const CheckedCheckboxIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		height={15}
		width={15}
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g data-name="check box">
			<g
				data-name="Ellipse 206"
				stroke="#000"
			>
				<circle
					cx={7.5}
					cy={7.5}
					r={7.5}
					stroke="none"
				/>
				<circle
					cx={7.5}
					cy={7.5}
					fill="none"
					r={7}
				/>
			</g>
			<path
				d="M3.7 7.498c.715.69 2.783 2.784 2.783 2.784l4.818-4.82"
				data-name="Path 3867"
				fill="none"
				stroke="#fff"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
	</svg>
);

export default CheckedCheckboxIcon;
