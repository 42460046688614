import React, { FC, HTMLProps } from 'react';
import styled from 'styled-components';

const AppButtonWrapper = styled.button`
    background-color: #6B257B;
    border: none;
    border-radius: 30px;
    color: #ffffff;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    line-height: 14px;
    padding: 12px 34px;
`;
const AppButton: FC<HTMLProps<HTMLButtonElement>> = (props) => {
	const { children } = props;
	return (<AppButtonWrapper {...props}>{children}</AppButtonWrapper>);
};
export default AppButton;
