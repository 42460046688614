import * as React from 'react';
import { SVGProps } from 'react';

const UncheckedInput = (props: SVGProps<SVGSVGElement>) => (
	<svg
		height={15}
		width={15}
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g
			data-name="check box"
			fill="#fff"
			stroke="rgba(0,0,0,0.5)"
		>
			<circle
				cx={7.5}
				cy={7.5}
				r={7.5}
				stroke="none"
			/>
			<circle
				cx={7.5}
				cy={7.5}
				fill="none"
				r={7}
			/>
		</g>
	</svg>
);

export default UncheckedInput;
