import React, { FC } from 'react';
import styled from 'styled-components';
import ClockIcon from '../../../../../icons/clockIcon';

const RestaurantAverageTimeWrapper = styled.div`
    align-items: center;
    display: flex;
`;

const RestaurantAverageTimeClockWrapper = styled.div`
    margin-right: 5px;
`;

const RestaurantAverageTimeEstimatedTimeWrapper = styled.div`
    color: #646464;
    font-size: 12px;
    line-height: 12px;
`;

interface RestaurantAverageTimeInterface {
    estimatedTime: string;
}

const RestaurantAverageTime: FC<RestaurantAverageTimeInterface> = ({ estimatedTime }) => (
	<RestaurantAverageTimeWrapper>
		<RestaurantAverageTimeClockWrapper>
			<ClockIcon />
		</RestaurantAverageTimeClockWrapper>
		<RestaurantAverageTimeEstimatedTimeWrapper>
			{estimatedTime}
		</RestaurantAverageTimeEstimatedTimeWrapper>
	</RestaurantAverageTimeWrapper>
);
export default RestaurantAverageTime;
