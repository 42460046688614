import { ItemInterface } from 'restpay-api-lib/lib/@types/_interfaces/menus/item.interface';
import { ModifierGroupInterface } from 'restpay-api-lib/lib/@types/_interfaces/menus/modifierGroup.interface';
import { FormItemDataInterface } from '../@types/_interfaces/menu/formItemData.interface';
import { GetMenuInterface } from '../state/@types/menu/menuStateTypes.interface';
import { ItemInOrderInterface } from '../state/@types/cart/itemInOrder.interface';
import { AddonInterface } from '../@types/_interfaces/menu/addon.interface';
import { ModifierInOrderInterface } from '../state/@types/cart/modifierInOrder.interface';
import { PickedModifierInterface } from '../state/@types/cart/pickedModifier.interface';
import { calculateModifierPrice } from './calculateModifierPrice';
import { translateTitle } from './translateTitle';

function excludeCounterModifierIfSummaryEqualsZero(e: [string, AddonInterface])	{
	if (e[1]?.quantitySummary > 0 || typeof e[1]?.quantitySummary === 'undefined') {
		return { [e[0]]: e[1] };
	}
}

function convertFormDataToArrayOfObjects(data: FormItemDataInterface) {
	return Object.entries(data.addons)
		.map((e) =>
			excludeCounterModifierIfSummaryEqualsZero(e))
		.filter((modifier) => typeof modifier !== 'undefined');
}

function pickedCategoriesToIds(pickedModifiers: { [p: string]: AddonInterface }[]) {
	return pickedModifiers.reduce((acc, cur) => [...acc, ...Object.keys(cur)], []);
}

function itemToPickedModifier(item: ItemInterface, groupOfModifiersId: string, quantity?: number): PickedModifierInterface {
	return {
		pickedModifierName: translateTitle(item.title),
		pickedModifierId: item.id,
		quantity: quantity || 1,
		price: calculateModifierPrice(item, groupOfModifiersId),
	};
}

function pickItem(
	menu: GetMenuInterface,
	pickInSingleCategory: string,
	pickedModifiersWithItems: ModifierInOrderInterface[],
	pickedModifiersDetails: ModifierGroupInterface,
) {
	const item = menu.items.find((item) => item.id === pickInSingleCategory);
	if (item) {
		pickedModifiersWithItems.push({
			modifier: pickedModifiersDetails,
			pickedModifiers: [
				itemToPickedModifier(item, pickedModifiersDetails.id),
			],
		});
	}
}

function pickMultipleItems(
	menu: GetMenuInterface,
	pickInSingleCategory: string[],
	pickedModifiersWithItems: ModifierInOrderInterface[],
	pickedModifiersDetails: ModifierGroupInterface,
) {
	const items = menu.items.filter((item) => pickInSingleCategory.some((singleItemId) => singleItemId === item.id));
	if (items) {
		pickedModifiersWithItems.push({
			modifier: pickedModifiersDetails,
			pickedModifiers: items.map((item) => itemToPickedModifier(item, pickedModifiersDetails.id)),
		});
	}
}

function pickMultipleItemsWithCounter(
	menu: GetMenuInterface,
	pickInSingleCategory: AddonInterface[],
	pickedModifiersWithItems: ModifierInOrderInterface[],
	pickedModifiersDetails: ModifierGroupInterface,
) {
	const pickedItemsWithQuantity: {id: string, quantity: number}[] = Object.entries(pickInSingleCategory)
		.reduce((acc, cur) => {
			if (cur[0] !== 'quantitySummary' && cur[1].quantity > 0) {
				return [...acc, {
					id: cur[0],
					...cur[1],
				}];
			}
			return acc;
		}, []);
	const items = menu.items.filter((item) => pickedItemsWithQuantity.some((itemIdAndQuantity) => itemIdAndQuantity.id === item.id));
	if (items) {
		pickedModifiersWithItems.push({
			modifier: pickedModifiersDetails,
			pickedModifiers: items.map((item) => itemToPickedModifier(item, pickedModifiersDetails.id, pickInSingleCategory[item.id].quantity)),
		});
	}
}

const convertFormDataToDisplayInCart = (data: FormItemDataInterface, menu: GetMenuInterface): ItemInOrderInterface => {
	const pickedItem = menu.items.find((item) => item.id === data.id);
	const pickedModifiersWithItems: ModifierInOrderInterface[] = [];
	if (data?.addons) {
		const pickedModifiers = convertFormDataToArrayOfObjects(data);
		const pickedCategoriesId = pickedCategoriesToIds(pickedModifiers);

		const pickedModifiersDetails = menu.modifierGroups.filter((menuModifier) =>
			pickedCategoriesId.some((addedModifier) => addedModifier === menuModifier.id));

		pickedModifiersDetails.forEach((pickedModifiersDetails) => {
			const pickInSingleCategory = data.addons[pickedModifiersDetails.id];
			if (typeof pickInSingleCategory === 'string') {
				pickItem(menu, pickInSingleCategory, pickedModifiersWithItems, pickedModifiersDetails);
			}
			if (Array.isArray(pickInSingleCategory)) {
				pickMultipleItems(menu, pickInSingleCategory, pickedModifiersWithItems, pickedModifiersDetails);
			}
			if (typeof pickInSingleCategory === 'object' && !Array.isArray(pickInSingleCategory)) {
				pickMultipleItemsWithCounter(menu, pickInSingleCategory, pickedModifiersWithItems, pickedModifiersDetails);
			}
		});
	}

	return {
		item: pickedItem,
		pickedModifiers: pickedModifiersWithItems,
	};
};
export default convertFormDataToDisplayInCart;
