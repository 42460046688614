import React, { FC } from 'react';
import styled from 'styled-components';
import AnimateHeight from 'react-animate-height';
import { ItemInterface } from 'restpay-api-lib/lib/@types/_interfaces/menus/item.interface';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AppButton from '../../../../../common/AppButton';
import PlusQuantityIcon from '../../../../../../icons/PlusQuantityIcon';
import MinusQuantityIcon from '../../../../../../icons/MinusQuantityIcon';
import useYupValidationResolver from '../../../../../../utils/useYupValidatorResolver';
import { GlobalState } from '../../../../../../state/@types/redux.interface';
import { FormItemDataInterface } from '../../../../../../@types/_interfaces/menu/formItemData.interface';
import convertFormDataToDisplayInCart from '../../../../../../utils/convertFormDataToDisplayInCart';
import { ItemInCartInterface } from '../../../../../../state/@types/cart/itemInCart.interface';
import { addItemToCartAction, clearCurrentRestaurantItemsAction } from '../../../../../../state/cart.reducer';
import { translateTitle } from '../../../../../../utils/translateTitle';
import ItemModifier from './itemModifier/ItemModifier';

const SingleItemText = styled.div`
    color: #000000;
    font-size: 14px;
    font-weight: bold;
`;
const SingleItemDescriptionText = styled.div`
    color: #646464;
    font-size: 12px;
`;
const SingleItemContentOpened = styled.div`
    display: flex;
    flex-direction: column;
    padding: 30px 20px;
`;
const SingleItemContentOpenedNameAndPrice = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
`;
const SingleItemContentOpenedDescription = styled.div`

`;
const SingleItemContentAddToCartSection = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
`;
const SingleItemContentQuantityNumber = styled.span`
    color: #646464;
    font-size: 14px;
    line-height: 14px;
`;
const SingleItemContentQuantityButtonsWrapper = styled.span`
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 58px;

    & > * {
        cursor: pointer;
    }
`;
const SingleItemPriceText = styled.div`
    color: #1E1E1C;
    font-size: 12px;
    font-weight: 500;
`;
const SingleItemGroupOfModifiersWrapper = styled.div`
    margin-top: 35px;
`;

const ItemModifierSeparator = styled.div`
    background-color: #CFCFCF;
    height: 1px;
    margin-bottom: 10px;
    width: 100%;
`;

interface SingleItemExpandedInterface {
    item: ItemInterface;
    checkIfOpened: () => boolean;
    validationSchema: unknown
}

const SingleItemExpanded: FC<SingleItemExpandedInterface> = ({
	checkIfOpened, item, validationSchema,
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const menuSelector = useSelector((state: GlobalState) => state.menu);
	const { menu } = menuSelector;

	const formOptions = { resolver: useYupValidationResolver(validationSchema) };
	const methods = useForm(formOptions);
	const {
		register, handleSubmit, control, reset,
	} = methods;

	const onSubmit = (formData: FormItemDataInterface) => {
		const item: ItemInCartInterface = {
			formData,
			display: convertFormDataToDisplayInCart(formData, menu),
			restaurantId: menu?.restaurant?.id,
			quantity: formData.quantity,
		};
		// TODO reset form after successful submit
		dispatch(addItemToCartAction(item));
	};

	return (
		<AnimateHeight
			duration={175}
			height={checkIfOpened() ? 'auto' : 0}
			hidden={!checkIfOpened()}
		>
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<input
						{...register('id')}
						type="hidden"
						value={item.id}
					/>
					<SingleItemContentOpened>
						<SingleItemContentOpenedNameAndPrice>
							<SingleItemText>
								{translateTitle(item?.title)}
							</SingleItemText>
							<SingleItemPriceText>
								{`${(item.priceInfo.price / 100).toFixed(2)} zł`}
							</SingleItemPriceText>
						</SingleItemContentOpenedNameAndPrice>
						<SingleItemContentOpenedDescription>
							<SingleItemDescriptionText>
								{translateTitle(item?.description)}
							</SingleItemDescriptionText>
						</SingleItemContentOpenedDescription>
						{item?.childModifierGroups?.length > 0 && (
							<SingleItemGroupOfModifiersWrapper>
								{item.childModifierGroups.map((groupOfModifiers) => (
									<>
										<ItemModifierSeparator />
										<ItemModifier
											key={groupOfModifiers.id}
											groupOfModifiersId={groupOfModifiers.id}
										/>
									</>
								))}
							</SingleItemGroupOfModifiersWrapper>
						)}
						<SingleItemContentAddToCartSection>
							<Controller
								control={control}
								defaultValue={0}
								name="quantity"
								render={({ field: { onChange, value } }) => (
									<>
										<AppButton
											style={{ backgroundColor: value > 0 ? '#6B257B' : '#CFCFCF' }}
											type="submit"
										>
											{t('common.add')}
										</AppButton>
										<SingleItemContentQuantityNumber>
											{t('common.quantity')}
											:&nbsp;
											{value}
										</SingleItemContentQuantityNumber>
										<SingleItemContentQuantityButtonsWrapper>
											<MinusQuantityIcon onClick={() => value > 0 && onChange(value - 1)} />
											<PlusQuantityIcon onClick={() => onChange(value + 1)} />
										</SingleItemContentQuantityButtonsWrapper>
									</>
								)}
							/>

						</SingleItemContentAddToCartSection>
					</SingleItemContentOpened>
				</form>
			</FormProvider>
		</AnimateHeight>
	);
};
export default SingleItemExpanded;
