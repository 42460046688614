import React, { FC } from 'react';
import styled from 'styled-components';
import { ItemInterface } from 'restpay-api-lib/lib/@types/_interfaces/menus/item.interface';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import usePrepareValidationForSingleItem from '../../../../../../utils/usePrepareValidationForSingleItem';
import SingleItemExpanded from './SingleItemExpanded';
import SingleItemClosed from './SingleItemClosed';

const SingleItemWrapper = styled.div<{isOpen: boolean}>`
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 3px 3px 5px 0 rgba(0,0,0,0.06);
    display: flex;
    flex-direction: ${({ isOpen }) => (isOpen ? 'column' : 'row')};
    height: ${({ isOpen }) => (isOpen ? '100%' : '110px')};
    margin-bottom: 10px;
    overflow: hidden;
    text-decoration: none;
    width: 100%;
`;
const SingleItemBackgroundImage = styled.div<{itemImagePath: string, isOpen: boolean}>`
    background-blend-mode: saturation;
    background-image: ${({ itemImagePath }) => `url(${itemImagePath})`};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    height: 110px;
    padding: 13px 23px;
    position: relative;
    transition: 0.1s width;
    width: ${({ isOpen }) => (isOpen ? '100%' : '240px')};
`;

interface SingleItemInterface {
    setOpenedItem: React.Dispatch<React.SetStateAction<string>>;
    openedItem: string;
    itemKey: string;
    item: ItemInterface;
}

const SingleItem: FC<SingleItemInterface> = ({
	itemKey, openedItem, setOpenedItem, item,
}) => {
	const validationSchema = usePrepareValidationForSingleItem(item);

	const checkIfOpened = () => itemKey === openedItem;

	const openOrClose = () => {
		if (checkIfOpened()) {
			setOpenedItem(null);
		} else {
			setOpenedItem(itemKey);
		}
	};

	return (
		<SingleItemWrapper
			isOpen={checkIfOpened()}
		>
			<SingleItemBackgroundImage
				isOpen={checkIfOpened()}
				itemImagePath={item?.images[0]?.url || '/table/Zjadłeś.jpg'}
				onClick={() => openOrClose()}
			/>
			{!checkIfOpened() && (
				<SingleItemClosed
					item={item}
					openOrClose={openOrClose}
				/>
			)}
			<SingleItemExpanded
				checkIfOpened={checkIfOpened}
				item={item}
				validationSchema={validationSchema}
			/>

		</SingleItemWrapper>
	);
};
export default SingleItem;
