/* eslint-disable */
import * as React from "react";
import { FC, SVGProps } from "react";

const SearchIcon: FC = (props: SVGProps<SVGSVGElement>) => (
    <svg height={13.118} viewBox="0 0 13.118 13.118" width={13.118} xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M9.375 8.25h-.592l-.21-.2a4.882 4.882 0 1 0-.525.525l.2.21v.592L12 13.118 13.118 12Zm-4.5 0A3.375 3.375 0 1 1 8.25 4.875 3.37 3.37 0 0 1 4.875 8.25Z"
            data-name="Path 3857"
        />
    </svg>
);

export default SearchIcon;
