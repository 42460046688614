import React, { FC } from 'react';
import styled from 'styled-components';
import { CategoryInterface } from 'restpay-api-lib/src/@types/_interfaces/menus/category.interface';
import { Link } from 'react-scroll';
import SearchIcon from '../../../../../icons/searchIcon';
import { translateTitle } from '../../../../../utils/translateTitle';

const CategoriesRowWrapper = styled.div`
    align-items: center;
    background-color: #FBFBFB;
    display: flex;
    height: 47px;
    margin-bottom: 25px;
    overflow-x: scroll;
    padding: 0 30px;
    position: sticky;
    top: 80px;
    width: 100%;
    z-index: 5;
`;

const SearchIconWrapper = styled.div`
    margin-right: 30px;
`;

const ScrollLink = styled(Link)`
    margin-right: 15px;
    &.active {
        font-weight: bold;
        position: relative;
        &::after {
            background-color: #000000;
            bottom: -2px;
            content: "";
            height: 1px;
            position: absolute;
            width: 100%;
        }
    }
`;

const SingleCategory = styled.div`
    white-space: nowrap;
`;

interface CategoriesRowInterface {
    categories: CategoryInterface[];
}

const CategoriesRow: FC<CategoriesRowInterface> = ({ categories }) => (
	<CategoriesRowWrapper>
		<SearchIconWrapper>
			<SearchIcon />
		</SearchIconWrapper>
		{categories?.map((category) => (
			<ScrollLink
				key={category.id}
				activeClass="active"
				duration={500}
				isDynamic
				offset={-100}
				smooth
				spy
				to={category.id}
			>
				<SingleCategory>{translateTitle(category.title)}</SingleCategory>
			</ScrollLink>
		))}
	</CategoriesRowWrapper>
);
export default CategoriesRow;
