import { Controller, useFormContext } from 'react-hook-form';
import React, { FC, useEffect, useState } from 'react';
import { ItemInterface } from 'restpay-api-lib/lib/@types/_interfaces/menus/item.interface';
import defineAddonInputName from '../../../utils/defineAddonInputName';
import { ModifierDisplayTypeEnum } from '../../../@types/_enums/menu/modifierDisplayType.enum';
import { calculateModifierPrice } from '../../../utils/calculateModifierPrice';
import { showPriceUtil } from '../../../utils/showPriceUtil';
import { translateTitle } from '../../../utils/translateTitle';
import { ItemModifierInputLabel, ItemModifierInputInput } from './shared';

interface SingleChoiceInterface {
    groupOfModifiersId: string;
    modifier: ItemInterface;
}

const SingleChoice: FC<SingleChoiceInterface> = ({ groupOfModifiersId, modifier }) => {
	const { control } = useFormContext();
	const [modifierPrice, setModifierPrice] = useState<number>(0);
	useEffect(() => {
		setModifierPrice(calculateModifierPrice(modifier, groupOfModifiersId));
	}, []);

	return (
		<Controller
			control={control}
			defaultValue={undefined}
			name={defineAddonInputName(ModifierDisplayTypeEnum.SINGLE_CHOICE, groupOfModifiersId)?.inputName}
			render={({
				field: {
					onChange, value,
				},
			}) => (
				<ItemModifierInputLabel checked={value === modifier.id}>
					<ItemModifierInputInput
						checked={value === modifier.id}
						onChange={onChange}
						onClick={(e) => e.target.value === value && onChange(undefined)}
						type="radio"
						value={modifier.id}
					/>
					{translateTitle(modifier.title)}
                    &nbsp;
					{modifierPrice > 0 && (
						<b>{`(+${showPriceUtil(modifierPrice)})`}</b>
					)}
				</ItemModifierInputLabel>
			)}
		/>
	);
};
export default SingleChoice;
