import React, { FC } from 'react';
import styled from 'styled-components';
import RatingStarIcon from '../../../../../icons/ratingStarIcon';

const RestaurantRatingWrapper = styled.div`
    align-items: center;
    display: flex;
`;

const RestaurantRatingStarWrapper = styled.div`
    align-items: center;
    display: flex;
    margin-right: 5px;
`;

const RestaurantRatingScoreWrapper = styled.div`
    align-items: center;
    display: flex;
    font-weight: 600;
    margin: 0 10px;
`;

interface RestaurantRating {
    ratingScore: number;
    numberOfOpinions: number;
}

const RestaurantRating: FC<RestaurantRating> = ({ ratingScore, numberOfOpinions }) => (
	<RestaurantRatingWrapper>
		{[...Array(Math.round(ratingScore || 1))].map((x, index) => (
			// eslint-disable-next-line react/no-array-index-key
			<RestaurantRatingStarWrapper key={index}>
				<RatingStarIcon />
			</RestaurantRatingStarWrapper>
		))}
		<RestaurantRatingScoreWrapper>
			{ratingScore ? ratingScore.toFixed(1) : '0.0'}
		</RestaurantRatingScoreWrapper>
		{`(${numberOfOpinions})`}
	</RestaurantRatingWrapper>
);
export default RestaurantRating;
