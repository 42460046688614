import styled from 'styled-components';

export const ItemModifierInputInput = styled.input`
	height: 15px;
	width: 15px;

	// hide vanilla inputs
	&[type="radio"], &[type="checkbox"] {
		/* Add if not using autoprefixer */
		-webkit-appearance: none;
		appearance: none;
		/* For iOS < 15 to remove gradient background */
		background-color: #fff;
		/* Not removed via appearance */
		margin: 0;
	}

	&[type="radio"] {
		border: 0.1em solid rgba(0, 0, 0, 0.5);
		border-radius: 50%;
		color: #000;
		display: grid;
		font: inherit;
		margin-right: 20px;
		place-content: center;
		transform: translateY(-0.075em);
	}

	&[type="radio"]::before {
		border-radius: 50%;
		box-shadow: inset 1em 1em #000;
		content: "";
		height: 0.65em;
		transform: scale(0);
		transition: 40ms transform ease-in-out;
		width: 0.65em;
	}

	&[type="radio"]:checked::before {
		transform: scale(1);
	}

	&[type="radio"]:checked {
		border: 0.1em solid #000;
	}

`;
export const ItemModifierInputLabel = styled.label<{ checked?: boolean }>`
    align-items: center;
    color: ${({ checked }) => (checked ? '#000000' : '#646464')};
	display: flex;
	font-weight: ${({ checked }) => (checked ? 'bold' : 'normal')};
`;
