import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { CategoryInterface } from 'restpay-api-lib/lib/@types/_interfaces/menus/category.interface';
import { ItemInterface } from 'restpay-api-lib/lib/@types/_interfaces/menus/item.interface';
import { Element } from 'react-scroll';
import { translateTitle } from '../../../../../utils/translateTitle';
import SingleItem from './singleItem/SingleItem';

const ItemsSectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 30px 30px;
`;

const SingleCategoryElement = styled(Element)`
    padding-bottom: 40px;
`;

const CategoryNameWrapper = styled.div`
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
`;

interface ItemsSection {
    categories: CategoryInterface[];
    items: ItemInterface[];
}

const ItemsSection: FC<ItemsSection> = ({ categories, items }) => {
	const [openedItem, setOpenedItem] = useState<string>(null);
	return (
		<ItemsSectionWrapper>
			{categories?.length && categories.map((category) => {
				const categoryItems = items.filter((item) => category.items.find((categoryItem) => categoryItem.id === item.id));
				return (
					<SingleCategoryElement
						key={category.id}
						name={category.id}
					>
						<CategoryNameWrapper>
							{translateTitle(category.title)}
						</CategoryNameWrapper>
						{categoryItems.map((item) => (
							<SingleItem
								key={`${category.id}|${item.id}`}
								item={item}
								itemKey={`${category.id}|${item.id}`}
								openedItem={openedItem}
								setOpenedItem={setOpenedItem}
							/>
						))}
					</SingleCategoryElement>
				);
			})}
		</ItemsSectionWrapper>
	);
};
export default ItemsSection;
