import React, { FC } from 'react';
import styled from 'styled-components';
import { ItemInterface } from 'restpay-api-lib/lib/@types/_interfaces/menus/item.interface';
import { translateTitle } from '../../../../../../utils/translateTitle';

const SingleItemContentClosed = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    padding: 15px;
    width: 100%;
`;
const SingleItemNameAndArrowWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
`;
const SingleItemTextTwoLines = styled.div`
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    color: #000000;
    display: -webkit-box;
    font-size: 14px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
`;
const SingleItemDescriptionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
`;
const SingleItemDescriptionTextTwoLines = styled.div`
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    color: #646464;
    display: -webkit-box;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
`;
const SingleItemPriceText = styled.div`
    color: #1E1E1C;
    font-size: 12px;
    font-weight: 500;
`;
interface SingleItemClosedInterface {
    item: ItemInterface;
    openOrClose: () => void;
}
const SingleItemClosed: FC<SingleItemClosedInterface> = ({ item, openOrClose }) => (
	<SingleItemContentClosed onClick={() => openOrClose()}>
		<SingleItemNameAndArrowWrapper>
			<SingleItemTextTwoLines>
				{translateTitle(item?.title)}
			</SingleItemTextTwoLines>
		</SingleItemNameAndArrowWrapper>
		<SingleItemDescriptionsWrapper>
			<SingleItemDescriptionTextTwoLines>
				{translateTitle(item?.description)}
			</SingleItemDescriptionTextTwoLines>
			<SingleItemPriceText>
				{`${(item.priceInfo.price / 100).toFixed(2)} zł`}
			</SingleItemPriceText>
		</SingleItemDescriptionsWrapper>
	</SingleItemContentClosed>
);
export default SingleItemClosed;
