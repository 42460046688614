import React, { FC } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import ArrowIcon from '../../../../icons/arrowIcon';
import InfoIcon from '../../../../icons/infoIcon';

const MenuDetailsMainImageWrapper = styled.div`
    background-image: url('${({ bgImg }) => bgImg}');
    background-position: center;
    background-size: cover;
    height: 144px;
    padding: 15px 30px;
    position: relative;
    width: 100%;
`;

const MenuDetailsMainImageFilter = styled.div`
    background: linear-gradient(rgba(0,0,0,0.47) 0%, rgba(255,255,255,0) 100%);
    height: 50%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
`;

const MenuDetailsMainImageContent = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    z-index: 2;
`;

const MenuDetailsMainImageArrowIcon = styled(Link)`
    cursor: pointer;
    transform: rotate(180deg);
    z-index: 3;
`;

const MenuDetailsMainImageInfoIcon = styled.div`
    cursor: pointer;
    z-index: 3;
`;

export interface MenuDetailsMainImageInterface {
    bgImg: string;
    tableId: string;
}

const MenuDetailsHeaderImage: FC<MenuDetailsMainImageInterface> = ({ bgImg, tableId }) => (
	<MenuDetailsMainImageWrapper bgImg={bgImg}>
		<MenuDetailsMainImageFilter />
		<MenuDetailsMainImageContent>
			<MenuDetailsMainImageArrowIcon to={`/table/menu?tableId=${tableId}`}>
				<ArrowIcon
					fill="#ffffff"
				/>
			</MenuDetailsMainImageArrowIcon>
			<MenuDetailsMainImageInfoIcon>
				<InfoIcon />
			</MenuDetailsMainImageInfoIcon>
		</MenuDetailsMainImageContent>
	</MenuDetailsMainImageWrapper>
);
export default MenuDetailsHeaderImage;
