import { ItemInterface } from 'restpay-api-lib/lib/@types/_interfaces/menus/item.interface';
import { ModifierDisplayTypeEnum } from '../@types/_enums/menu/modifierDisplayType.enum';
import { DefineAddonInputNameInterface } from '../@types/_interfaces/menu/defineAddonInputName.interface';

function defineAddonInputName(modifierDisplayTypeEnum: ModifierDisplayTypeEnum, groupOfModifiersId: string, modifier?: ItemInterface)
	: DefineAddonInputNameInterface {
	if (modifierDisplayTypeEnum === ModifierDisplayTypeEnum.SINGLE_CHOICE || modifierDisplayTypeEnum === ModifierDisplayTypeEnum.MULTIPLE_CHOICE) {
		return {
			inputName: `addons.${groupOfModifiersId}`,
		};
	}
	if (modifierDisplayTypeEnum === ModifierDisplayTypeEnum.MULTIPLE_CHOICE_WITH_COUNTER) {
		return {
			inputName: `addons.${groupOfModifiersId}.${modifier?.id}`,
			amountInputName: `addons.${groupOfModifiersId}.${modifier?.id}.quantity`,
			allQuantitySummary: `addons.${groupOfModifiersId}.quantitySummary`,
		};
	}
}

export default defineAddonInputName;
