import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ItemInterface } from 'restpay-api-lib/lib/@types/_interfaces/menus/item.interface';
import { QuantityInfoInterface } from 'restpay-api-lib/lib/@types/_interfaces/menus/quantityInfo.interface';
import { Controller, useFormContext } from 'react-hook-form';
import defineAddonInputName from '../../../utils/defineAddonInputName';
import PlusQuantityIcon from '../../../icons/PlusQuantityIcon';
import MinusQuantityIcon from '../../../icons/MinusQuantityIcon';
import { ModifierDisplayTypeEnum } from '../../../@types/_enums/menu/modifierDisplayType.enum';
import CheckedCheckboxIcon from '../../../icons/CheckedCheckboxIcon';
import UncheckedInput from '../../../icons/UncheckedInput';
import { calculateModifierPrice } from '../../../utils/calculateModifierPrice';
import { showPriceUtil } from '../../../utils/showPriceUtil';
import { translateTitle } from '../../../utils/translateTitle';
import { ItemModifierInputInput, ItemModifierInputLabel } from './shared';

const MultipleChoiceWithCounterWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
`;
const MultipleChoiceWithCounterButtonsWrapper = styled.div`
    display: flex;
`;
const CounterNumber = styled.div<{ maxPermittedPerOption: number, value: number }>`
    color: ${({ maxPermittedPerOption, value }) => (maxPermittedPerOption > value ? '#000' : 'red')};
    font-size: 18px;
    font-weight: bold;
    padding: 0 10px;
`;

interface MultipleChoiceWithCounterInterface {
    modifier: ItemInterface;
    groupOfModifiersId: string;
    quantityInfo: QuantityInfoInterface;
    refreshQuantitySummaryInMultipleChoiceWithCounter: () => void;
}

const MultipleChoiceWithCounter: FC<MultipleChoiceWithCounterInterface> = ({
	modifier,
	groupOfModifiersId,
	quantityInfo,
	refreshQuantitySummaryInMultipleChoiceWithCounter,
}) => {
	const { setValue, control } = useFormContext();
	const { maxPermittedPerOption } = quantityInfo;

	const [isChecked, setIsChecked] = useState<boolean>(false);
	const [modifierPrice, setModifierPrice] = useState<number>(0);

	useEffect(() => {
		setModifierPrice(calculateModifierPrice(modifier, groupOfModifiersId));
	}, []);

	const changeQuantity = (onChange, newValue) => {
		if (newValue === 0) {
			setIsChecked(false);
		}
		if (maxPermittedPerOption >= newValue) {
			onChange(newValue);
		}
		refreshQuantitySummaryInMultipleChoiceWithCounter();
		onChange(newValue);
	};

	const changeShowCounter = (value) => {
		if (value) {
			setValue(defineAddonInputName(ModifierDisplayTypeEnum.MULTIPLE_CHOICE_WITH_COUNTER,
				groupOfModifiersId,
				modifier)
				.amountInputName, 1);
		} else {
			setValue(defineAddonInputName(ModifierDisplayTypeEnum.MULTIPLE_CHOICE_WITH_COUNTER,
				groupOfModifiersId,
				modifier)
				.amountInputName, 0);
		}
		refreshQuantitySummaryInMultipleChoiceWithCounter();
		setIsChecked(value);
	};

	// TODO SHOW ERRORS IN MULTIPLE CHOICE WITH COUNTER???
	return (
		<MultipleChoiceWithCounterWrapper>
			 {/* {errors[defineAddonInputName(ModifierDisplayTypeEnum.MULTIPLE_CHOICE_WITH_COUNTER, */}
			{/* groupOfModifiersId, */}
			{/* modifier) */}
			{/* .amountInputName]?.message && ( */}
			{/* <div> */}
			{/*	{errors[defineAddonInputName(ModifierDisplayTypeEnum.MULTIPLE_CHOICE_WITH_COUNTER, */}
			{/*		groupOfModifiersId, */}
			{/*		modifier) */}
			{/*		.amountInputName]?.message} */}
			{/* </div> */}
			 {/* )} */}
			<ItemModifierInputLabel checked={isChecked}>
				{isChecked ? <CheckedCheckboxIcon /> : <UncheckedInput />}
				<ItemModifierInputInput
					checked={isChecked}
					onChange={(e) => changeShowCounter(e.target.checked)}
					type="checkbox"
				/>
				{translateTitle(modifier.title)}
                &nbsp;
				{modifierPrice > 0 && (
					<b>{`(+${showPriceUtil(modifierPrice)})`}</b>
				)}
			</ItemModifierInputLabel>
			<Controller
				control={control}
				defaultValue={0}
				name={defineAddonInputName(ModifierDisplayTypeEnum.MULTIPLE_CHOICE_WITH_COUNTER,
					groupOfModifiersId,
					modifier)
					.amountInputName}
				render={({
					field: {
						onChange, value,
					},
				}) => (
					<MultipleChoiceWithCounterButtonsWrapper>
						{isChecked && (
							<>
								<MinusQuantityIcon onClick={() => changeQuantity(onChange, value - 1)} />
								<CounterNumber
									maxPermittedPerOption={maxPermittedPerOption}
									value={value}
								>
									{value}
								</CounterNumber>
								{maxPermittedPerOption !== value ? <PlusQuantityIcon onClick={() => changeQuantity(onChange, value + 1)} /> : <span style={{ width: 24 }} />}
							</>
						)}
					</MultipleChoiceWithCounterButtonsWrapper>
				)}
			/>
		</MultipleChoiceWithCounterWrapper>
	);
};
export default MultipleChoiceWithCounter;
