import { ItemInterface } from 'restpay-api-lib/lib/@types/_interfaces/menus/item.interface';
import { useState } from 'react';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { ModifierGroupInterface } from 'restpay-api-lib/lib/@types/_interfaces/menus/modifierGroup.interface';
import { GlobalState } from '../state/@types/redux.interface';

function multipleChoiceValidation(
	modifiersValidation: Record<string, unknown>,
	itemModifier: ModifierGroupInterface,
	minPermitted: number,
	maxPermitted: number,
) {
	return {
		...modifiersValidation,
		[`${itemModifier.id}`]: yup.array()
			.required()
			.min(minPermitted, `Wybierz minimum ${minPermitted}`)
			.required()
			.max(maxPermitted, `Wybierz maksymalnie ${maxPermitted}`),
	};
}

function singleChoiceValidation(minPermitted: number, modifiersValidation: Record<string, unknown>, itemModifier: ModifierGroupInterface) {
	if (minPermitted === 1) {
		modifiersValidation = {
			...modifiersValidation,
			[`${itemModifier.id}`]: yup.string().required('To pole jest wymagane'),
		};
	}
	return modifiersValidation;
}

function multipleChoiceWithCounterValidation(menu: GetMenuInterface, itemModifier: ModifierGroupInterface, maxPermittedPerOption, modifiersValidation: {}, minPermitted, maxPermitted) {
	const itemsInModifier = menu?.items.filter((item) =>
		itemModifier?.childItems?.some((itemInModifier) => itemInModifier.id === item.id));

	let maxItemsInMultipleChoicePerOption = {};
	itemsInModifier.forEach((item) => {
		maxItemsInMultipleChoicePerOption = {
			...maxItemsInMultipleChoicePerOption,
			[item.id]: yup.object().shape({
				quantity: yup.number().max(maxPermittedPerOption),
			}),
		};
	});
	return {
		...modifiersValidation,
		[itemModifier.id]: yup.object().shape({
			...maxItemsInMultipleChoicePerOption,
			quantitySummary: yup.number()
				.transform((value) => parseInt(value, 10))
				.required()
				.min(minPermitted, `Wybierz minimum ${minPermitted}`)
				.required()
				.max(maxPermitted, `Wybierz maksymalnie ${maxPermitted}`),
		}),
	};
}

const usePrepareValidationForSingleItem = (item: ItemInterface): any => {
	const menuSelector = useSelector((state: GlobalState) => state.menu);
	const { menu } = menuSelector;

	const [validationSchema, setValidationSchema] = useState(null);

	if (!validationSchema) {
		let modifiersValidation = {};
		const allItemModifiers = menu?.modifierGroups?.filter((menuModifier) =>
			item?.childModifierGroups?.some((itemModifier) => menuModifier.id === itemModifier.id));

		allItemModifiers.forEach((itemModifier) => {
			const { minPermitted, maxPermitted, maxPermittedPerOption } = itemModifier?.quantityInfo;

			if (maxPermitted === 1) {
				modifiersValidation = singleChoiceValidation(minPermitted, modifiersValidation, itemModifier);
			}
			if (maxPermitted > 1 && maxPermittedPerOption === 1) {
				modifiersValidation = multipleChoiceValidation(modifiersValidation, itemModifier, minPermitted, maxPermitted);
			}
			if (maxPermitted > 1 && maxPermittedPerOption > 1) {
				modifiersValidation = multipleChoiceWithCounterValidation(
					menu,
					itemModifier,
					maxPermittedPerOption,
					modifiersValidation, minPermitted,
					maxPermitted,
				);
			}
		});

		const generatedSchema = yup.object().shape({
			quantity: yup.number().min(1),
			addons: yup.object().shape({
				...modifiersValidation,
			}),
		});
		setValidationSchema(generatedSchema);
		return generatedSchema;
	}
	return validationSchema;
};
export default usePrepareValidationForSingleItem;
