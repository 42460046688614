import React, { FC } from 'react';
import styled from 'styled-components';

const RestaurantDescriptionWrapper = styled.div`
    color: #646464;
    font-size: 12px;
    line-height: 12px;
`;

interface RestaurantDescriptionInterface {
    restaurantDescription: string;
}

const RestaurantDescription: FC<RestaurantDescriptionInterface> = ({ restaurantDescription }) => (
	<RestaurantDescriptionWrapper>
		{restaurantDescription}
	</RestaurantDescriptionWrapper>
);
export default RestaurantDescription;
