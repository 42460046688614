import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { ModifierGroupInterface } from 'restpay-api-lib/lib/@types/_interfaces/menus/modifierGroup.interface';
import AnimateHeight from 'react-animate-height';
import { ItemInterface } from 'restpay-api-lib/lib/@types/_interfaces/menus/item.interface';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { GlobalState } from '../../../../../../../state/@types/redux.interface';
import ItemModifierInput from '../../../../../../common/itemModifierInputs/ItemModifierInput';
import defineAddonInputName from '../../../../../../../utils/defineAddonInputName';
import { ModifierDisplayTypeEnum } from '../../../../../../../@types/_enums/menu/modifierDisplayType.enum';
import { translateTitle } from '../../../../../../../utils/translateTitle';

const ItemModifierWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
`;
const ItemModifierHeader = styled.div`
    display: flex;
    justify-content: space-between;
`;
const ItemModifierName = styled.div`
    font-size: 14px;
    font-weight: 500;
`;
const ItemModifierExpand = styled.div`
    color: #646464;
    font-size: 14px;
`;
const ErrorsSection = styled.div`
    color: #ff0000;
    font-size: 8px;
    margin-bottom: 7px;
`;

interface ItemModifierInterface {
    groupOfModifiersId: string;
}

const ItemModifier: FC<ItemModifierInterface> = ({
	groupOfModifiersId,
}) => {
	const { t } = useTranslation();

	const [modifierDetails, setModifierDetails] = useState<ModifierGroupInterface>(null);
	const [modifierItems, setModifierItems] = useState<ItemInterface[]>([]);
	const [isModifierExpanded, setIsModifierExpanded] = useState<boolean>(false);
	const [modifierDisplayType, setModifierDisplayType] = useState<ModifierDisplayTypeEnum>(null);
	const {
		register, formState: { errors }, setValue, getValues,
	} = useFormContext();
	const menuSelector = useSelector((state: GlobalState) => state.menu);

	const { menu } = menuSelector;

	useEffect(() => {
		const currentModifier = menu?.modifierGroups?.find((modifier) => modifier.id === groupOfModifiersId) || null;
		const currentModifierItems = menu?.items?.filter((menuItem) =>
			currentModifier?.childItems.some((modifierItem) => menuItem.id === modifierItem.id));

		if (currentModifier && !modifierDetails) {
			setModifierDetails(currentModifier);
		}
		if (currentModifierItems?.length > 0 && modifierItems.length === 0) {
			setModifierItems(currentModifierItems);
		}
	}, []);

	useEffect(() => {
		if (modifierDetails) {
			const { maxPermitted, maxPermittedPerOption } = modifierDetails?.quantityInfo;
			if (maxPermitted === 1) {
				setModifierDisplayType(ModifierDisplayTypeEnum.SINGLE_CHOICE);
			}
			if (maxPermitted > 1 && maxPermittedPerOption === 1) {
				setModifierDisplayType(ModifierDisplayTypeEnum.MULTIPLE_CHOICE);
			}
			if (maxPermitted > 1 && maxPermittedPerOption > 1) {
				setModifierDisplayType(ModifierDisplayTypeEnum.MULTIPLE_CHOICE_WITH_COUNTER);
			}
		}
	}, [modifierDetails]);

	const refreshQuantitySummaryInMultipleChoiceWithCounter = () => {
		if (modifierDisplayType === ModifierDisplayTypeEnum.MULTIPLE_CHOICE_WITH_COUNTER) {
			const currentValues = getValues(`addons.${groupOfModifiersId}`);

			const quantityFields: {quantity: number}[] = Object.values(currentValues);

			const quantityOfWholeModifier = quantityFields
				.filter((value) => typeof value === 'object') // remove quantity summary field
				.reduce((acc, cur) => acc += cur.quantity, 0);

			setValue(
				defineAddonInputName(ModifierDisplayTypeEnum.MULTIPLE_CHOICE_WITH_COUNTER, groupOfModifiersId).allQuantitySummary,
				quantityOfWholeModifier,
			);
		}
	};

	return (
		<ItemModifierWrapper>
			<div onClick={() => setIsModifierExpanded(!isModifierExpanded)}>
				<ItemModifierHeader>
					<ItemModifierName>{translateTitle(modifierDetails?.title)}</ItemModifierName>
					<ItemModifierExpand>
						{t('common.choose')}
					</ItemModifierExpand>
				</ItemModifierHeader>
				{errors[`addons.${modifierDetails?.id}`]?.message && (
					<ErrorsSection>
						{errors[`addons.${modifierDetails?.id}`]?.message}
					</ErrorsSection>
				)}
				{modifierDisplayType === ModifierDisplayTypeEnum.MULTIPLE_CHOICE_WITH_COUNTER && errors[`addons.${modifierDetails?.id}.quantitySummary`]?.message && (
					<ErrorsSection>
						{errors[`addons.${modifierDetails?.id}.quantitySummary`]?.message}
					</ErrorsSection>
				)}
			</div>
			<AnimateHeight
				duration={175}
				height={isModifierExpanded ? 'auto' : 0}
			>
				{modifierItems.map((modifier) => (
					<ItemModifierInput
						key={modifier.id}
						groupOfModifiersId={groupOfModifiersId}
						modifier={modifier}
						modifierDisplayType={modifierDisplayType}
						quantityInfo={modifierDetails?.quantityInfo}
						refreshQuantitySummaryInMultipleChoiceWithCounter={refreshQuantitySummaryInMultipleChoiceWithCounter}
					/>
				))}
			</AnimateHeight>
			{modifierDisplayType === ModifierDisplayTypeEnum.MULTIPLE_CHOICE_WITH_COUNTER && (
				<input
					defaultValue={0}
					type="hidden"
					{...register(defineAddonInputName(ModifierDisplayTypeEnum.MULTIPLE_CHOICE_WITH_COUNTER, groupOfModifiersId).allQuantitySummary)}
				/>
			)}
		</ItemModifierWrapper>
	);
};
export default ItemModifier;
